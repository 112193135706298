import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import solutionsData from '../helpers/SolutionsData.json'
import accent3 from '../assets/Accent3.png'
import placeholderimg from '../assets/placeholder-image.png'
import clientbg from '../assets/client-background.jpg'
import { Carousel } from 'flowbite-react'

function SolutionsContents() {
    const [seeMore, setSeeMore] = useState(false)
    const [benefitIndex, setBenefitIndex] = useState(null)

    const {category, solution} = useParams()

    const categoryIndex = solutionsData[0].solutions.findIndex(item => item.name === category)
    const solutionIndex = solutionsData[0].solutions[categoryIndex].solution_items.findIndex(item => item.name === solution)

    const selectedSolution = solutionsData[0].solutions[categoryIndex].solution_items[solutionIndex]

    function seeMoreToggle(index) {
        if (seeMore){
            setSeeMore(false)
            setBenefitIndex(null)
        } else {
            setSeeMore(true)
            setBenefitIndex(index)
        }
    }

      const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-blueaccent",
          },
          base: "h-2 w-2 rounded-full",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-blueprimary/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 text-blueprimary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };

  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:pt-40 pt-24' onClick={seeMore ? () => {setSeeMore(false); setBenefitIndex(null)}: ()=> {}}>
        {categoryIndex === -1 || solutionIndex === -1 ? (
            <div>Content unavailable</div>
        ) : (
        <>
        <div className='w-full relative flex justify-center'>
            <div className='w-full h-full flex justify-center items-center -z-10 absolute'>
                <img src={accent3} alt='' className='w-1/3' style={{animation: `spin linear 20s infinite`}}/>
            </div>
            <div className='relative lg:w-4/6 w-11/12 lg:flex lg:flex-row flex flex-col gap-5 lg:gap-24'>
                <div className='w-full flex flex-col gap-12'>
                    <div className='w-4/6 flex flex-col gap-1'>
                        <span className='font-plusjakarta text-blueaccent'>{selectedSolution.tagline}</span>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{selectedSolution.name}</span>
                    </div>
                    <div className='font-raleway'>{selectedSolution.summary}</div>
                </div>
                <div className='lg:w-3/4 w-full h-96 bg-white rounded-xl'>
                    <img src={selectedSolution.image ? require(`../assets/solutions/${selectedSolution.image}`):placeholderimg} alt='' className='object-cover w-full h-full rounded-xl'/>
                </div>
            </div>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col lg:gap-5 gap-2 justify center items-center mt-12 mb-24'>
            <span className='font-plusjakarta lg:text-2xl text-xl w-full font-bold'>{seeMore ? "Technical Benefits" : "Benefits for Your Business"}</span>
            <div className='w-full grid lg:grid-cols-3 grid-cols-1 lg:gap-8 gap-2'>
                {selectedSolution.technical_benefits.map((item, index) => (
                    <div className={`w-full p-4 bg-white shadow-xl rounded-lg font-raleway flex flex-col justify-between gap-4 transition-all duration-300 ${benefitIndex===index && seeMore ? 'scale-105': "h-72"} ${benefitIndex!==index && benefitIndex !== null ? 'blur-sm' :""}`} key={index}>
                        <div className='flex flex-col gap-4'>
                            <span className='font-bold text-bluehover text-lg'>{item.benefit}</span>
                            <span>{seeMore && benefitIndex === index ? item.so_what : item.you_language}</span>
                        </div>
                        {/* {seeMore && benefitIndex === index && (<div>{item.so_what}</div>)} */}
                        <div className='w-full flex justify-end'><div className={`font-raleway text-sm text-blueaccent hover:cursor-pointer ${benefitIndex === null || benefitIndex === index ? 'hover:text-bluehover': ''}`} onClick={benefitIndex === null || benefitIndex === index ? () => seeMoreToggle(index) : () => {}}>{seeMore && benefitIndex === index ? 'Close' : 'See More'}</div></div>
                    </div>
                ))}
            </div>
        </div>
        {selectedSolution.principals.length !== 0 ? (
        <div className='relative h-auto lg:py-40 py-10 flex justify-center items-center w-full bg-cover bg-center' style={{backgroundImage: `url(${clientbg})`, backgroundSize:`cover`}}>
            <div className='absolute bg-white inset-0 opacity-80 w-full h-full'></div>
            <div className='relative lg:w-4/6 lg:h-4/6 flex flex-col lg:gap-12 gap-2 w-11/12'>
                <div className='flex flex-col gap-1' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <span className='font-plusjakarta text-blueaccent'>Products</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-xl'>Get to know our products</span>
                </div>
                {selectedSolution.principals.length > 1 ? (
                    <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <Carousel pauseOnHover theme={customTheme} className='h-80'>
                        {selectedSolution.principals.map((data,index) => (
                            <div key={index} className='lg:w-4/5 lg:h-80 lg:flex lg:flex-row lg:gap-12 lg:justify-center lg:items-center w-8/12 grid grid-cols-2 place-items-center gap-2'>
                                {data.map((principal, principalIndex) => (
                                    <div className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center' key={principalIndex}><img src={principal.image ? require(`../assets/principals/${principal.image}`) : placeholderimg} alt='' className='w-full'/></div>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
                ) : (
                    <>
                    <div className='flex lg:gap-12 lg:justify-start lg:items-center gap-5'>
                    {selectedSolution.principals[0].map((data,index) => (
                            <div key={index} className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center'><img src={data.image ? require(`../assets/principals/${data.image}`) : placeholderimg} alt='' className='w-full'/></div>
                        ))}
                        </div>
                    </>
                )}
                {/* <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    {selectedSolution.principals.length > 1 ? (
                    <Slider {...settings}>
                    {selectedSolution.principals.map(({image, link}, index) => (
                        <Link to={link} target='_blank'><img src={image ? require(`../assets/principals/${image}`) : placeholderimg} className='w-48' alt='' key={index}/></Link>
                    ))}
                    </Slider>
                    ) : (
                        <Link to={selectedSolution.principals[0].link} target='_blank'><img src={require(`../assets/principals/${selectedSolution.principals[0].image}`)} className='h-40' alt=''/></Link>
                    )}
                </div> */}
            </div>
        </div>
        ) : (null)}
        </>)}
    </div>
    </>
  )
}

export default SolutionsContents