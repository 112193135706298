import React, {useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import solutionsData from '../helpers/SolutionsData.json'
import accent1 from '../assets/Accent1.png'
import placeholderimg from '../assets/placeholder-image.png'
import Button from '../components/Button'
import { HiWrenchScrewdriver, HiKey } from "react-icons/hi2";
import { FaCircle } from "react-icons/fa6";
import clientbg from '../assets/client-background.jpg'
import { Carousel } from 'flowbite-react'


function CategoryContents() {
    const [seeMore, setSeeMore] = useState(false)
    const [benefitIndex, setBenefitIndex] = useState(null)

    const {type, category} = useParams()
    // const removeHyphen = category.replace(/-/g, " ")
    // function capitalizeFirstLetter(val) {
    //    return val.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    // }
    // const formattedCategory = capitalizeFirstLetter(removeHyphen)
    const solutionsDataIndex = type === "solutions" ? 0 : 1
    const categoryIndex = solutionsDataIndex === 0 ? solutionsData[solutionsDataIndex].solutions.findIndex(item => item.name === category) : solutionsData[solutionsDataIndex].services.findIndex(item => item.name === category)
    const selectedCategory = solutionsDataIndex === 0 ? solutionsData[solutionsDataIndex].solutions[categoryIndex] : solutionsData[solutionsDataIndex].services[categoryIndex]

    function seeMoreToggle(index) {
        if (seeMore){
            setSeeMore(false)
            setBenefitIndex(null)
        } else {
            setSeeMore(true)
            setBenefitIndex(index)
        }
    }

      const customTheme = {
        root: {
          base: "relative h-full w-full",
          leftControl:
            "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
          rightControl:
            "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none bg-white/0",
        },
        indicators: {
          active: {
            off: "bg-black/50 hover:bg-black/70 dark:bg-gray-800/50 dark:hover:bg-gray-800",
            on: "bg-blueaccent",
          },
          base: "h-2 w-2 rounded-full",
        },
        control: {
            base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-blueprimary/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-blueprimary sm:h-10 sm:w-10",
            icon: "h-5 w-5 text-blueprimary dark:text-gray-800 sm:h-6 sm:w-6"
        },
        scrollContainer: {
            base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll overflow-x-hidden lg:scroll-smooth rounded-lg",
            snap: "snap-x"
        }
      };

  return (
    <>
    <div className='flex flex-grow w-full flex-col items-center lg:pt-40 pt-24' onClick={seeMore ? () => {setSeeMore(false); setBenefitIndex(null)}: ()=> {}}>
        {categoryIndex !== -1 && solutionsDataIndex === 1 ? (
            <>
            <div className='w-full relative flex justify-center'>
                <div className='w-full h-full flex justify-center items-center -z-10 absolute'>
                    <img src={accent1} alt='' className='w-1/3' style={{animation: `spin linear 20s infinite`}}/>
                </div>
                <div className='relative lg:w-4/6 w-11/12 lg:flex lg:flex-row flex flex-col gap-5 lg:gap-24 justify-start items-center'>
                    <div className='w-full flex flex-col gap-12'>
                        <div className='lg:w-4/6 w-full flex flex-col gap-4'>
                            <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{selectedCategory.name}</span>
                            <span className='font-plusjakarta text-blueaccent italic'>{selectedCategory.tagline}</span>
                        </div>
                    </div>
                    <div className='lg:w-3/4 h-96 w-full bg-white rounded-xl'>
                        <img src={selectedCategory.image ? require(`../assets/solutions/${selectedCategory.image}`) : placeholderimg } alt='' className='object-cover w-full h-full rounded-xl'/>
                    </div>
                </div>
            </div>
            {selectedCategory.summary.length !== 0 && (selectedCategory.name === 'Premium Support and Maintenance' || selectedCategory.name === 'Standard Support and Maintenance') ? (
                <div className='grid grid-cols-1 lg:w-4/6 w-11/12 mt-12 mb-24'>
                    <Link to={'https://support.bajau.com/'} target='_blank' className='font-raleway rounded-xl shadow-xl p-5 flex justify-center bg-blueprimary hover:bg-bluehover text-white w-full'>{selectedCategory.summary[0].pointer}</Link></div>
            ) : (
                <div className={`grid lg:grid-cols-3 lg:gap-8 grid-cols-1 gap-2 lg:w-4/6 w-11/12 mt-12 mb-24`}>
                    {selectedCategory.summary.map((data, index) => (
                        <div key={index} className='font-raleway rounded-xl shadow-xl p-5 flex justify-center bg-bluehover text-white w-full'>{data.pointer}</div>
                    ))}
                </div>
            )}
            {/* {selectedCategory.summary.length !== 0 ? (
                <div className={`grid lg:grid-cols-3 lg:gap-8 grid-cols-1 gap-2 lg:w-4/6 w-11/12 mt-12 mb-24`}>
                    {selectedCategory.summary.map((data, index) => (
                        <div key={index} className='font-raleway rounded-xl shadow-xl p-5 flex justify-center bg-bluehover text-white w-full'>{data.pointer}</div>
                    ))}
                </div>
            ) :(null)} */}
            {selectedCategory.why && (
                <div className='lg:w-4/6 w-11/12 flex flex-col gap-5 lg:mb-24 my-12'>
                    <span className='font-plusjakarta lg:text-2xl text-xl font-bold'>{selectedCategory.why}</span>
                    <div className='w-full grid lg:grid-cols-3 grid-cols-1 gap-2 lg:gap-8'>
                        {selectedCategory.benefits.map((data, index) => (
                            <div key={index} className='bg-white rounded-xl shadow-xl font-raleway p-5 flex justify-center items-center text-center font-bold text-blueprimary'>{data.benefit}</div>
                        ))}
                    </div>
                </div>
            )}
            {selectedCategory.with_our_service.length !==0 && selectedCategory.key_services_provided.length !==0 ? (
                <div className='w-full bg-blue-950 lg:py-24 py-10 flex justify-center items-center'>
                    <div className='lg:w-3/6 w-11/12 flex flex-col lg:gap-24 gap-10'>
                        <div className='flex flex-col gap-5' style={{animation: `appear-right linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='font-plusjakarta font-bold text-white lg:text-4xl text-2xl'>With Our Service</span>
                            <div className='lg:flex lg:flex-row flex flex-col gap-5 lg:gap-10 items-center'>
                                <div className='lg:h-60 lg:w-80 w-32 h-32 rounded-lg'>
                                    <HiWrenchScrewdriver className='h-full w-full text-white opacity-50'/>
                                </div>
                                <div className='w-full flex flex-col justify-between gap-5'>
                                    {selectedCategory.with_our_service.map((data,index) => (
                                        <div className='flex gap-4 items-center'>
                                            <div className='h-2 w-2'><FaCircle className='w-2 h-2 text-white'/></div>
                                            <span key={index} className='font-raleway text-white'>{data.pointer}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5' style={{animation: `appear-left linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                            <span className='font-plusjakarta font-bold text-white lg:text-4xl text-2xl w-full lg:text-right text-left'>Key Service Provided</span>
                            <div className='lg:flex lg:flex-row flex flex-col-reverse gap-5 lg:gap-10 items-center'>
                                <div className='w-full flex flex-col justify-between gap-5'>
                                    {selectedCategory.key_services_provided.map((data,index) => (
                                        <div className='flex gap-4 items-center'>
                                            <div className='h-2 w-2'><FaCircle className='w-2 h-2 text-white' /></div>
                                            <span key={index} className='font-raleway text-white'>{data.pointer}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className='lg:h-60 lg:w-80 w-32 h-32 rounded-lg'>
                                    <HiKey className='h-full w-full text-white opacity-50'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (null)}
            </>
        ) : (
        <>
        <div className='w-full relative flex justify-center'>
            <div className='w-full h-full flex justify-center items-center -z-10 absolute'>
                <img src={accent1} alt='' className='w-1/3' style={{animation: `spin linear 20s infinite`}}/>
            </div>
            <div className='relative lg:w-4/6 lg:flex lg:flex-row lg:gap-24 flex flex-col gap-5 w-11/12'>
                <div className='w-full flex flex-col gap-12'>
                    <div className='lg:w-4/6 w-11/12 flex flex-col gap-1'>
                        <span className='font-plusjakarta text-blueaccent'>{selectedCategory.tagline}</span>
                        <span className='font-plusjakarta font-bold lg:text-4xl text-2xl'>{selectedCategory.name}</span>
                    </div>
                    <div className='font-raleway'>{selectedCategory.summary}</div>
                </div>
                <div className='lg:w-3/4 w-full h-96 bg-white rounded-xl'>
                    <img src={selectedCategory.image ? require(`../assets/solutions/${selectedCategory.image}`) : placeholderimg} alt='' className='object-cover w-full h-full rounded-xl'/>
                </div>
            </div>
        </div>
        <div className='lg:w-4/6 w-11/12 flex flex-col lg:gap-5 gap-2 justify center items-center mt-12 mb-24'>
            <span className='font-plusjakarta lg:text-2xl w-full font-bold text-xl'>Technical Benefits</span>
            <div className='w-full grid lg:grid-cols-3 lg:gap-8 grid-cols-1 gap-2'>
                {selectedCategory.technical_benefits.map((item, index) => (
                    <div className={`w-full p-4 bg-white shadow-xl rounded-lg font-raleway flex flex-col justify-between gap-4 transition-all duration-300 ${benefitIndex===index && seeMore ? 'scale-105': "h-72"} ${benefitIndex!==index && benefitIndex !== null ? 'blur-sm' :""}`} key={index}>
                        <div className='flex flex-col gap-4'>
                            <span className='font-bold text-bluehover text-lg'>{item.benefit}</span>
                            <span>{seeMore && benefitIndex === index ? item.so_what : item.you_language}</span>
                        </div>
                        {/* {seeMore && benefitIndex === index && (<div>{item.so_what}</div>)} */}
                        <div className='w-full flex justify-end'><div className={`font-raleway text-sm text-blueaccent hover:cursor-pointer ${benefitIndex === null || benefitIndex === index ? 'hover:text-bluehover': ''}`} onClick={benefitIndex === null || benefitIndex === index ? () => seeMoreToggle(index) : () => {}}>{seeMore && benefitIndex === index ? 'Close' : 'See More'}</div></div>
                    </div>
                ))}
            </div>
        </div>
        {selectedCategory.solution_items.length !== 0 ? 
        <div className='w-full bg-bluehover py-24 flex justify-center items-center'>
            <div className='lg:w-3/6 w-11/12 flex flex-col gap-24'>
                {selectedCategory.solution_items.map((item, index) => (
                    <div className='lg:flex lg:flex-row flex flex-col gap-5 lg:gap-10 items-center' key={index} style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                        <div className='h-60 lg:w-80 w-11/12 bg-white rounded-lg'>
                            <img src={item.image ? require(`../assets/solutions/${item.image}`) : placeholderimg} alt='' className='w-full h-full object-cover rounded-lg'/>
                        </div>
                        <div className='w-full flex flex-col justify-between gap-5'>
                            <div className='flex flex-col gap-2 text-white'>
                                <span className='font-plusjakarta lg:text-2xl text-xl font-bold'>{item.name}</span>
                                <span className='font-raleway'>{item.summary}</span>
                            </div>
                            <Link to={`/solutions-and-services/solutions/${category}/${item.name}`}><Button buttonType={"button"} label={"Read More"} overrideClass={'bg-blueaccent h-10 w-40 text-white font-plusjakarta flex justify-center items-center rounded-md hover:bg-white hover:text-blueprimary'}/></Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        : null
        }
        {selectedCategory.principals.length !== 0 ? (
        <div className='relative h-auto lg:py-40 py-10 flex justify-center items-center w-full bg-cover bg-center' style={{backgroundImage: `url(${clientbg})`, backgroundSize:`cover`}}>
            <div className='absolute bg-white inset-0 opacity-80 w-full h-full'></div>
            <div className='relative lg:w-4/6 lg:h-4/6 flex flex-col lg:gap-12 gap-2 w-11/12'>
                <div className='flex flex-col gap-1' style={{animation: `appear-bottom linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <span className='font-plusjakarta text-blueaccent'>Products</span>
                    <span className='font-plusjakarta font-bold lg:text-4xl text-xl'>Get to know our products</span>
                </div>
                {selectedCategory.principals.length > 1 ? (
                    <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    <Carousel pauseOnHover theme={customTheme} className='h-80'>
                        {selectedCategory.principals.map((data,index) => (
                            <div key={index} className='lg:w-4/5 lg:h-80 lg:flex lg:flex-row lg:gap-12 lg:justify-center lg:items-center w-8/12 grid grid-cols-2 place-items-center gap-2'>
                                {data.map((principal, principalIndex) => (
                                    <div className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center' key={principalIndex}><img src={principal.image ? require(`../assets/principals/${principal.image}`) : placeholderimg} alt='' className='w-full'/></div>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                </div>
                ) : (
                    <>
                    <div className='flex lg:gap-12 lg:justify-start lg:items-center gap-5'>
                    {selectedCategory.principals[0].map((data,index) => (
                            <Link key={index} to={data.link} target='_blank' className='lg:w-52 lg:h-52 w-28 h-28 p-1 rounded-xl bg-white shadow-xl lg:p-4 flex justify-center items-center'><img src={data.image ? require(`../assets/principals/${data.image}`) : placeholderimg} alt='' className='w-full'/></Link>
                        ))}
                        </div>
                    </>
                )}
                {/* <div className='w-full h-full' style={{animation: `appear-top linear`, animationTimeline: `view()`, animationRange: `entry 0% cover 30%`}}>
                    {selectedCategory.principals.length > 1 ? (
                    <Slider {...settings}>
                    {selectedCategory.principals.map(({image, link}, index) => (
                        <Link to={link} target='_blank'><img src={image ? require(`../assets/principals/${image}`) : placeholderimg} className='w-48' alt='' key={index}/></Link>
                    ))}
                    </Slider>
                    ) : (
                        <Link to={selectedCategory.principals[0][0].link} target='_blank'><img src={require(`../assets/principals/${selectedCategory.principals[0][0].image}`)} className='h-40' alt=''/></Link>
                    )}
                </div> */}
            </div>
        </div>
        ) : (null)}
        </>
        )}
    </div>
    </>
  )
}

export default CategoryContents