import React from 'react'
import logo from '../assets/Logo Bajau_Terang.png'
import { FaFacebook, FaInstagram, FaLinkedin, FaPhoneAlt } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Footer() {
    const location = useLocation()
    const navigate = useNavigate()
    const mainmenu = [
        {menu: "Home", to: "/"},
        {menu: "About Us", to: "/about-us"},
        {menu: "Contact Us", to: "/contact-us"},
        // {menu: "Blogs & News", to: "/blogs-and-news"},
        {menu: "Careers", to: "/careers"},
    ]
    
    const mainMenuFooterMobile  = [
        {menu: "Home", to: "/"},
        {menu: "Solutions & Services", to: "/solutions-and-services"},
        {menu: "About Us", to: "/about-us"},
        {menu: "Contact Us", to: "/contact-us"},
        // {menu: "Blogs & News", to: "/blogs-and-news"},
        {menu: "Careers", to: "/careers"},
    ]
    const solutions = [
        {menu: "Workload Automation"},
        {menu: "Infrastructure & Security Solutions"},
        {menu: "Backups & Disaster Recovery"},
        {menu: "Enterprise Cloud Solutions"},
        {menu: "Data Analytics & Business Intelligence"},
        {menu: "Software Automation Testing"},
    ]
    const supports = [
        {menu: "Premium Support and Maintenance"},
        {menu: "Standard Support and Maintenance"},
    ]
    const manageServices = [
        {menu: "Manage Service - Manage File Transfer (MFT)"},
        {menu: "Manage Service - Workload Automation"},
        {menu: "Manage Service - Software Testing"},
    ]
  return (
    <>
    <div className='hidden lg:h-96 lg:w-full lg:bottom-0 lg:bg-neutraldark lg:flex lg:justify-center lg:items-center'>
        <div className='xl:w-3/4 lg:w-11/12 lg:gap-10 flex justify-between'>
            <div className='flex flex-col gap-4 xl:w-96 lg:w-72'>
                <div className='h-18'>
                    <img src={logo} className='w-48' alt=''/>
                </div>
                <div className='font-raleway font-bold text-white'>
                    <span>PT Bajau Escorindo</span>
                </div>
                <div className='font-raleway text-white'>
                    <span>18 Office Park, 22nd Fl, Suite E,F & G, Jl. TB Simatupang Kav 18, South Jakarta City, Jakarta 12520</span>
                </div>
                <div className='font-raleway text-white flex justify-between items-center'>
                    <div className='flex gap-2 items-center'>
                        <FaPhoneAlt />
                        <a href='tel:+622150578999' className='hover:underline'>+62 21 50578999</a>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <Link to={'https://id.linkedin.com/company/pt-bajau-escorindo'} target='_blank'><FaLinkedin /></Link>
                        <Link to={'https://www.instagram.com/bajauescorindo/'} target='_blank'><FaInstagram /></Link>
                        {/* <Link to={'https://www.facebook.com/people/Bajau-Escorindo/pfbid0jSWwdeZ4GgxihnAWBCV2tU6jomho74zZoBtKHiaDjLbcmby4HSHhFJyq2ZYoMAeTl/'} target='_blank'><FaFacebook /></Link> */}
                    </div>
                </div>
            </div>
            <div className='flex gap-10'>
                <div className='flex flex-col gap-2'>
                    {mainmenu.map(({menu, to}, index) => (
                        <div key={index} onClick={location.pathname === to ? () => {window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          })} : () => {navigate(to)}} className='text-white font-raleway font-bold hover:underline hover:cursor-pointer'>{menu}</div>
                    ))}
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='font-raleway font-bold text-white'>
                        <div onClick={location.pathname === '/solutions-and-services' ? () => {window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          })} : () => {navigate('/solutions-and-services')}} className='hover:underline hover:cursor-pointer'>Solutions & Services</div>
                    </div>
                    <div className='flex gap-10'>
                        <div className='flex flex-col gap-2'>
                            <span className='font-raleway font-bold text-blueaccent'>Solutions</span>
                            {solutions.map(({menu}, index) => (
                                <Link to={`/solutions-and-services/solutions/${menu}`} key={index} className='font-raleway text-white hover:underline'>{menu}</Link>
                            ))}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='font-raleway font-bold text-blueaccent'>Support and Maintenance</span>
                            {supports.map(({menu}, index) => (
                                <Link to={`/solutions-and-services/services/${menu}`} key={index} className='font-raleway text-white hover:underline'>{menu}</Link>
                            ))}
                            <span className='font-raleway font-bold text-blueaccent'>Managed Services</span>
                            {manageServices.map(({menu}, index) => (
                                <Link to={`/solutions-and-services/services/${menu}`} key={index} className='font-raleway text-white hover:underline'>{menu}</Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='lg:hidden w-full bg-neutraldark flex flex-col gap-5 justify-center items-center py-5'>
        <div className='w-11/12 h-12'>
            <img src={logo} className='h-full' alt=''/>
        </div>
        <div className='w-11/12 flex flex-col gap-4'>
            {mainMenuFooterMobile.map(({menu,to}, index) => (
                <Link to={to} key={index} className='font-plusjakarta text-white font-bold'>{menu}</Link>
            ))}
        </div>
        <div className='w-11/12 h-0.5 bg-white'></div>
        <div className='w-11/12 text-white font-raleway'>
            <span>18 Office Park, 22nd Fl, Suite E,F & G, Jl. TB Simatupang No.Kav 18, RT.2/RW.1, Kebagusan, Pasar Minggu, South Jakarta City, Jakarta 12520</span>
        </div>
        <div className='w-11/12 flex justify-between'>
            <div className='flex gap-2 items-center text-white font-raleway'>
                <FaPhoneAlt />
                <a href='tel:+622150578999' className='hover:underline'>+62 21 50578999</a>
            </div>
            <div className='flex gap-4 items-center text-white'>
                <Link to={'https://id.linkedin.com/company/pt-bajau-escorindo'} target='_blank' className='h-6 w-6'><FaLinkedin className='h-full w-full'/></Link>
                <Link to={'https://www.instagram.com/bajauescorindo/'} target='_blank' className='h-6 w-6'><FaInstagram className='h-full w-full'/></Link>
                {/* <Link to={'https://www.facebook.com/people/Bajau-Escorindo/pfbid0jSWwdeZ4GgxihnAWBCV2tU6jomho74zZoBtKHiaDjLbcmby4HSHhFJyq2ZYoMAeTl/'} target='_blank' className='h-6 w-6'><FaFacebook className='h-full w-full'/></Link> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer